const majors = [
  'Computer science',
  'Biology/biological sciences',
  'Labor and industrial relations',
  'Hotel/motel administration/management',
  'Agricultural economics',
  'Biological and biomedical sciences',
  'Econometrics and quantitative economics',
  'Information technology',
  'Mechanical engineering',
  'Political science and government',
  'Animal sciences',
  'Electrical and electronics engineering',
  'Mathematics',
  'Communication',
  'Natural resources/conservation',
  'Operations research',
  'Psychology',
  'Agriculture',
  'Architectural and building sciences/technology',
  'Human development and family studies',
  'Sociology',
  'Chemical engineering',
  'Public policy analysis',
  'Chemistry',
  'Physics',
  'History',
  'Bioengineering and biomedical engineering',
  'English language and literature',
  'Nutrition sciences',
  'Statistics',
  'International public health/international health',
  'Civil engineering',
  'Biometry/biometrics',
  'City/urban, community and regional planning',
  'Agricultural engineering',
  'Plant sciences',
  'Food science',
  'Engineering physics/applied physics',
  'Fine/studio arts',
  'Environmental/environmental health engineering',
  'Philosophy',
  'Materials engineering',
  'International agriculture',
  'American/united states studies/civilization',
  'Apparel and textiles',
  'Asian studies/civilization',
  'Spanish language and literature',
  'Computer and information sciences',
  'Linguistics',
  'Near and middle eastern studies',
  'General studies',
  'Landscape architecture',
  'Geological and earth sciences/geosciences',
  'Anthropology',
  'Classics and classical languages, literatures, and linguistics',
  'Visual and performing arts',
  'Comparative literature',
  'French language and literature',
  'Engineering',
  'Entomology',
  'Music',
  'Italian language and literature',
  'Astronomy',
  'Archeology',
  'Science, technology and society',
  'Art history, criticism and conservation',
  'Atmospheric sciences and meteorology',
  'Gay/lesbian studies',
  'African-american/black studies',
  'Textile science',
  'Religion/religious studies',
  'German studies',
  'Economics',
]

export default majors
